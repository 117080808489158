
import { defineComponent, onMounted, ref, reactive, nextTick } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import IntegerInput from "@/components/ABilling/IntegerInput.vue";
import { useRoute, useRouter } from "vue-router";
import {
  getPanel,
  postAction,
  deleteEncounter,
  voidAction,
  checkEncounterTransactions,
  searchEncountersV2,
  getFilters,
  updateReasonCode,
  checkEncounterHasOpenClaims,
  checkEncounterMaxClaimAmount,
  checkEncountersMaxClaimAmount,
} from "@/api/encounter.api";
import useFilters from "@/modules/common/useFilters";
import { useStore } from "vuex";
import Multiselect from "@vueform/multiselect";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Encounter } from "@/modules/encounter/encounter.model";
import PaginationUi from "@/components/ABilling/Pagination.vue";
import { getList } from "@/api/dropdown.api";
import { getRehab, updateArchiveOlderThanDays } from "@/api/rehab.api";
import { searchDictInsuranceCompanys } from "@/api/code-master-insurance-company.api";
import { selfPayer } from "@/modules/patientPayer/patientPayer.model";
import NoteComponent from "@/modules/note/NotesComponent.vue";
import { EncounterStatusList } from "@/store/enums/StoreEnums";
import debounce from "lodash.debounce";
import DateFloatComponent from "@/components/ABilling/DateFloatComponent.vue";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}
export interface StatusCode {
  code: string;
  description: string;
  statusFrom?: any[];
  statusFromExc?: any[];
  action?: number;
}

export interface Reason {
  value: string;
  caption: string;
}

export interface Data {
  emptyListError?: string;
  selectAll: boolean;
  items: Encounter[];
  selectedItems: Encounter[];
  keyword: string;
  pagination: Pagination;
  orderBy: string;
  isDecr: boolean;
  panel: any;
  archive: boolean;
  archiveOlderThanDays: number | null;
  IsAdvancedOrderBy: boolean;
  filter: {
    allSelected: boolean;
    startDate: Date | string | null;
    endDate: Date | string | null;
    currentResponsibleParty: [];
    status: [];
    facility: [];
    provider: [];
    globalSearch: string | null;
  };
  dict: {
    encounterStatusCodes: StatusCode[];
    encounterActions: StatusCode[];
    encounterEllipsisActions: StatusCode[];
    patientPayers: { payerId: string; name: string }[];
    facilities: { id: string; name: string }[];
    providers: { id: string; fullName: string; lastName: string }[];
    actualEncounterStatusCodes: StatusCode[];
    reasons: Reason[];
    actualPatientPayers: { payerId: string; name: string }[];
    actualFacilities: { id: string; name: string }[];
    actualProviders: { id: string; fullName: string; lastName: string }[];
  };
  modalEncounterId;
  modalPatientId;
  modal;
}
export default defineComponent({
  name: "ServiceManagement",
  components: {
    Multiselect,
    PaginationUi,
    IntegerInput,
    NoteComponent,
    DateFloatComponent,
  },
  beforeRouteLeave(to, from, next) {
    next();
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { orderList } = useFilters();

    orderList.value = [
      {
        name: "Encounter ID",
        key: "encounterId",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Claim ID",
        key: "claimId",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: true,
      },
      {
        name: "Patient",
        key: "fullName",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: true,
      },
      {
        name: "DOS",
        isFilter: true,
        key: "dos",
        formType: "isDate",
        keyword: null,
        IsAdvancedOrderBy: true,
      },
      {
        name: "Facility",
        isFilter: true,
        key: "facility.Name",
        keyword: null,
        formType: "facility",
      },
      {
        name: "Attending Provider",
        isFilter: true,
        key: "facilityEncounter.attendingProvider.name",
        keyword: null,
        formType: "attendingProvider",
        IsAdvancedOrderBy: true,
      },
      {
        name: "Rendering Provider",
        isFilter: true,
        key: "professionalEncounter.renderingProvider.name",
        keyword: null,
        formType: "renderingProvider",
        IsAdvancedOrderBy: true,
      },
      {
        name: "Responsible Party",
        isFilter: true,
        key: "responsibleParty",
        keyword: null,
        IsAdvancedOrderBy: true,
        formType: "patientPayer",
      },
      {
        name: "Total Charges",
        isFilter: true,
        key: "totalCharges",
        keyword: null,
        IsAdvancedOrderBy: true,
      },
      {
        name: "Claim Type",
        key: "encounterType",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Reason",
        key: "reason",
        keyword: null,
        IsAdvancedOrderBy: true,
        isFilter: false,
      },
      {
        name: "Status",
        key: "status",
        isFilter: true,
        keyword: null,
        formType: "multiselect",
      },
    ];

    let organizationId = ref<string | null>("");

    let data = reactive<Data>({
      selectAll: false,
      items: [],
      selectedItems: [],
      keyword: "",
      orderBy: "default",
      IsAdvancedOrderBy: true,
      isDecr: true,
      archive: false,
      archiveOlderThanDays: null,
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 50,
      },
      panel: {},
      dict: {
        encounterActions: [],
        encounterStatusCodes: [],
        patientPayers: [],
        facilities: [],
        providers: [],
        reasons: [],
        actualEncounterStatusCodes: [],
        actualPatientPayers: [],
        actualFacilities: [],
        encounterEllipsisActions: [],
        actualProviders: [],
      },
      filter: {
        allSelected: false,
        startDate: null,
        endDate: null,
        facility: [],
        currentResponsibleParty: [],
        status: [],
        provider: [],
        globalSearch: null,
      },
      modalEncounterId: null,
      modalPatientId: null,
      modal: false,
    });

    async function cleanFilter() {
      data.filter = [] as never;
      data.keyword = "";
      data.selectedItems = [];
      data.selectAll = false;
      data.archiveOlderThanDays = 0;
      data.archive = false;
      data.orderBy = "default";
      data.isDecr = true;
      data.IsAdvancedOrderBy = true;
      orderList.value.forEach((item) => {
        item.keyword = null;
      });
      await getAll();
    }

    onMounted(async () => {
      organizationId.value = getOrganization();
      setCurrentPageTitle("Service Management");

      data.dict.encounterEllipsisActions = [
        { code: "copy", description: "Copy" },
        { code: "edit", description: "Edit" },
        {
          code: "hold",
          description: "Hold",
          statusFrom: [EncounterStatusList.Ready_To_Bill],
          action: 1,
        },
        {
          code: "removehold",
          description: "Remove Hold",
          statusFrom: [EncounterStatusList.On_Hold],
          action: 2,
        },
        {
          code: "402",
          description: "Void",
          statusFrom: [
            EncounterStatusList.Bill_Patient,
            EncounterStatusList.Submitted_To_Clearinghouse,
          ],
        },
        {
          code: "submit",
          description: "Submit",
          statusFrom: [EncounterStatusList.Ready_To_Bill],
          action: 4,
        },
        {
          code: "401",
          description: "Delete",
          statusFrom: [EncounterStatusList.Needs_Attention],
        },
      ];

      data.dict.patientPayers = await searchDictInsuranceCompanys({
        search: "",
      });

      const currentRehab = await getRehab(store.getters.selectedRehab.id);

      data.archiveOlderThanDays = currentRehab.archiveOlderThanDays;

      if (!data.archiveOlderThanDays) {
        data.archiveOlderThanDays = 120;
      }

      data.dict.reasons = await getList({
        type: "Reason",
      });

      getFiltersFromUrl();

      if (!data.filter.status || data.filter.status.length < 1) {
        if (route.query && route.query.status) {
          data.filter.status = [route.query.status] as any;
        } else {
          data.filter.status = [
            EncounterStatusList.Needs_Attention,
            EncounterStatusList.Ready_To_Bill,
            EncounterStatusList.On_Hold,
          ] as any;
        }
      }

      data.filter.endDate = new Date().toISOString();
      data.filter.startDate = new Date();
      data.filter.startDate.setDate(
        data.filter.startDate.getDate() - data.archiveOlderThanDays
      );
      data.filter.startDate = data.filter.startDate.toISOString();

      const panel = await getPanel();
      data.dict.facilities = panel.facilities;
      data.dict.providers = panel.providers;
      await getAll(true);
    });

    async function selectFilter(header) {
      if (!header.key || !header.isFilter) {
        return;
      }
      if (data.orderBy == header.key || data.orderBy == header.orderProperty) {
        data.isDecr = !data.isDecr;
      } else {
        data.isDecr = false;
      }
      if (header.orderProperty) {
        data.orderBy = header.orderProperty;
      } else {
        data.orderBy = header.key;
      }

      data.IsAdvancedOrderBy = header.IsAdvancedOrderBy;

      await search();
    }

    function getSortInfo(key, orderProperty) {
      if (data.orderBy == key || data.orderBy == orderProperty) return true;
      return false;
    }

    async function clearSearch() {
      await search();
    }

    async function debounceSearch() {
      await debounce(search, useFilters().debounceMs)();
    }

    async function search() {
      await nextTick();
      data.selectedItems = [];
      data.selectAll = false;
      data.pagination.currentPage = 1;
      await getAll();
    }

    async function pageChanged(page) {
      data.pagination.currentPage = page;
      data.selectedItems = [];
      data.selectAll = false;
      await getAll();
    }

    async function pageSizeChanged(pageSize) {
      data.pagination.pageSize = pageSize;
      data.pagination.currentPage = 1;
      data.selectedItems = [];
      data.selectAll = false;
      await getAll();
    }

    async function getAll(doUpdateFilters = false) {
      putFiltersToUrl();

      data.dict.encounterStatusCodes = store.getters.allEncounterStatuses;
      data.dict.encounterActions = store.getters.allEncounterActions.filter(
        (action) => {
          return action.code != 3 && action.code != 0;
        }
      );

      let order = data.orderBy;

      if (data.isDecr) {
        order = order + " Desc";
      }

      let orderBy: string[] | null = null;
      let advancedOrderBy: string | null = null;

      if (order && !data.IsAdvancedOrderBy) {
        orderBy = [order];
      } else {
        advancedOrderBy = order;
      }
      let constList = orderList.value
        .filter((item) => {
          return !item.IsAdvancedOrderBy;
        })
        .map((item) => {
          return item.key;
        });
      constList.push("patient.firstName");

      let archiveDate: Date | null = null;
      if (data.archiveOlderThanDays && data.archiveOlderThanDays > 0) {
        let dayNow = new Date();
        dayNow.setDate(dayNow.getDate() - data.archiveOlderThanDays);
        archiveDate = dayNow;
      }
      let request = {
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        orderBy: orderBy,
        advancedOrderBy: advancedOrderBy,
        advancedSearch: {
          fields: constList,
          keyword: data.keyword,
        },
        GlobalSearch: data.filter.globalSearch,
        DosStart: data.filter.startDate,
        DosEnd: data.filter.endDate,
        Providers: data.filter.provider,
        Facilities: data.filter.facility,
        Status: data.filter.status,
        ResponsibleParties: data.filter.currentResponsibleParty,
        ArchiveOlderThanDays: archiveDate,
      };

      const res = await searchEncountersV2(request);
      data.items = res.data;
      data.pagination.currentPage = res.currentPage;
      data.pagination.totalPages = res.totalPages;
      data.pagination.totalCount = res.totalCount;
      data.pagination.pageSize = res.pageSize;

      data.emptyListError = undefined;

      const currentFilters = orderList.value.filter((f) => {
        return f.keyword != undefined && f.keyword !== "" && f.keyword != null;
      });
      if (data.items.length == 0) {
        if (currentFilters.length > 0) {
          data.emptyListError =
            "**No results match your filter criteria. Please clear all filters to view list";
        } else {
          data.emptyListError = "List is Empty";
        }
      }
      if (doUpdateFilters) {
        const filters = await getFilters();
        updateFilters(filters);
      }
    }

    function updateFilters(res) {
      //by the requet result update filters to contains only actual data
      data.dict.actualPatientPayers = [];
      res.responsibleParty.forEach((element) => {
        let party = data.dict.patientPayers.find((x) => x.payerId === element);
        if (party) {
          data.dict.actualPatientPayers.push(party);
        }
      });
      data.dict.actualPatientPayers.sort((a, b) => (a.name > b.name ? 1 : -1));

      data.dict.actualPatientPayers.unshift(selfPayer);

      data.dict.actualProviders = data.dict.providers.filter((c) => {
        return res.providers.find((f) => {
          return f == c.id;
        });
      });
      data.dict.actualProviders.sort((a, b) =>
        a.lastName > b.lastName ? 1 : -1
      );

      data.dict.actualFacilities = data.dict.facilities.filter((c) => {
        return res.facility.find((f) => {
          return f == c.id;
        });
      });
      data.dict.actualFacilities.sort((a, b) => (a.name > b.name ? 1 : -1));

      data.dict.actualEncounterStatusCodes =
        data.dict.encounterStatusCodes.filter((c) => {
          return res.status.find((f) => {
            return f == c.code;
          });
        });
      data.dict.actualEncounterStatusCodes.sort((a, b) =>
        a.code > b.code ? 1 : -1
      );
    }

    async function updateReason(item) {
      await updateReasonCode(item.id, item.reason);
    }

    async function updateReasonToEmpty(item) {
      await updateReasonCode(item.id, "");
    }

    function selectItem(item) {
      const path = encodeURIComponent(route.fullPath);
      router.push({
        path: "encounter/" + item.id,
        query: { breadcrumb: path },
      });
    }

    function redirectToClaim(item) {
      router.push({
        path: "/claimManagement/claim/" + item.id + "/claimSummary",
      });
    }

    async function selectAllItems() {
      if (data.filter.allSelected) {
        data.selectedItems = data.items as any;
      } else {
        data.selectedItems = [];
      }
    }

    async function selectAllItemsIfManuallySelectedAll() {
      if (data.selectedItems.length == data.items.length) {
        data.filter.allSelected = true;
      } else {
        data.filter.allSelected = false;
      }
    }

    function newEncounter() {
      const path = encodeURIComponent(route.fullPath);
      router.push({
        path: "encounterNew",
        query: { breadcrumb: path },
      });
    }

    function getStatusHeader(code) {
      if (data.dict.encounterStatusCodes) {
        const status = data.dict.encounterStatusCodes.find((item) => {
          return item.code == code;
        });
        if (status) {
          return status.description;
        }
      }
      return code;
    }

    async function sendLineAction(action, item) {
      console.log("sendLineAction", action, item);
      let text = "";
      if (action.code === "401")
        text =
          "Are you sure you want to Delete Encounter#" + item.encounterId + "?";
      if (action.code === "402")
        text =
          "Are you sure you want to Void Encounter#" + item.encounterId + "?";
      if (action.code === "copy")
        text =
          "Are you sure you want to Copy Encounter#" + item.encounterId + "?";
      if (action.code === "edit")
        text =
          "Are you sure you want to Edit Encounter#" + item.encounterId + "?";
      if (action.code === "hold")
        text =
          "Are you sure you want to Hold Encounter#" + item.encounterId + "?";
      if (action.code === "removehold")
        text =
          "Are you sure you want to Remove Hold Encounter#" +
          item.encounterId +
          "?";
      if (action.code === "submit")
        text =
          "Are you sure you want to Submit Hold Encounter#" +
          item.encounterId +
          "?";

      // { code: "copy", description: "Copy" },
      //   { code: "edit", description: "Edit" },
      //   { code: "hold", description: "Hold", statusFrom: ["ReadyToBill"], action: 1 },
      //   { code: "removehold", description: "Remove Hold", statusFrom: ["Hold"], action: 2 },
      //   { code: "402", description: "Void"},
      //   { code: "401", description: "Delete" },
      Swal.fire({
        title: text,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        allowOutsideClick: false,
        customClass: {
          cancelButton: "ab-button-secondary",
          confirmButton: "ab-button",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (action.code === "401") {
            await deleteEncounterAction(item);
          }
          if (action.code === "copy") {
            router.push({
              path: "encounterNew",
              query: {
                encounterForCopy: item.id,
                breadcrumb: "/serviceManagement/",
              },
            });
          }
          if (
            action.code === "hold" ||
            action.code === "removehold" ||
            action.code == "submit"
          ) {
              await sendActionInt([item], action.action);
              await getAll(true);
          }
          if (action.code == "edit") {
            selectItem(item);
          }
          if (action.code === "402") {
            if (
              await checkEncounterHasOpenClaims({
                id: item.id,
              })
            ) {
              Swal.fire({
                icon: "error",
                title: "Error!",
                text:
                  "Encounter cannot be Voided. 1 or more active Claims are associated to this Encounter.",
                customClass: {
                  cancelButton: "ab-button-secondary",
                  confirmButton: "ab-button",
                },
              });
              return;
            }
            // TODO: void encounter
            const check = await checkEncounterTransactions({ id: item.id });
            if (check) {
              Swal.fire({
                title:
                  "You are voiding an Encounter which has associated payments/adjustments. Would you like to reverse these payments/adjustments?",
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: "Yes",
                denyButtonText: "No",
                allowOutsideClick: false,
                customClass: {
                  cancelButton: "ab-button-secondary",
                  confirmButton: "ab-button",
                },
              }).then(async (result) => {
                if (result.isConfirmed) {
                  voidEncounter(item);
                }
              });
            } else {
              voidEncounter(item);
            }
          }
        }
      });
    }

    async function deleteEncounterAction(item) {
      await deleteEncounter(item.id);
      await getAll(true);
      const text = "Encounter ID " + item.encounterId + " successfully deleted";
      Swal.fire({
          title: text,
          confirmButtonText: "Ok!",
          icon: "success",
          customClass: {
            confirmButton: "ab-button",
          },
        });
    }

    async function voidEncounter(item) {
      const res = await voidAction({
        id: item.id,
        entryDate: new Date(),
      });
      if (res) {
        Swal.fire({
          title: "Success!",
          text: "Encounter " + item.encounterId + " was successfully Voided",
          icon: "success",
          customClass: {
            confirmButton: "ab-button",
          },
        });
        await getAll(true);
        console.log("void encounter");
      }
    }

    async function sendAction(action) {
      await sendActionInt(data.selectedItems, action);
      data.selectedItems = [];
      data.selectAll = false;
      data.filter.allSelected = false;
      await search();
    }

    async function sendActionInt(encounters, action) {
      if (action == 4) {
        const check = await checkEncountersMaxClaimAmount({
          ids: encounters.map((item) => {
            return item.id;
          }),
        });

        if (!check) {
          let txt = "some Encounters";
          if(encounters.length == 1) txt = "this Encounter";
          const res = await Swal.fire({
            title:
              "The Total Charges for " + txt + " exceeds the Max Claim Amount for this Insurance. Are you sure you want to Proceed?",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Yes",
            denyButtonText: "Cancel",
            allowOutsideClick: false,
            customClass: {
              cancelButton: "ab-button-secondary",
              confirmButton: "ab-button",
            },
          });
          if (!res.isConfirmed) return false;
        }
      }

      const res = await postAction({
        encountersId: encounters.map((item) => {
          return item.id;
        }),
        encounterAction: action,
      });
      if (!res) return;
      let actionsText = {
        0: "approved",
        1: "placed on hold",
        2: "ready to bill",
        4: "submitted",
      };
      let actionText = actionsText[action];
      let success = res.successList.length;
      let total = encounters.length;
      let text = `(${success} of ${total}) Encounters were successfully ${actionText}.`;
      if (res.errors && res.errors.length > 0) {
        text = text + "\n";
        res.errors.forEach((error) => {
          text = text + "\n" + error;
        });
        Swal.fire({
          title: "Info!",
          text,
          icon: "info",
          customClass: {
            confirmButton: "ab-button",
          },
        });
      } else {
        Swal.fire({
          title: text,
          confirmButtonText: "Ok!",
          icon: "success",
          customClass: {
            confirmButton: "ab-button",
          },
        });
      }
    }

    // :class="{ 'disabled' : item.status === 'Billed' && action.code === '401'}"
    function checkDisplay(item, action) {
      if (action.code === "402") {
        if (item.currentResponsibleParty || item.status == "Voided")
          return false;
      }
      return true;
    }

    function getFiltersFromUrl() {
      const filters = route.query.filters as string;
      if (filters) {
        const propsName = Object.getOwnPropertyNames(data.filter);
        const filtersArray = JSON.parse(filters);

        propsName.forEach((p) => {
          data.filter[p] = filtersArray.find((i) => {
            return i.k == p;
          })?.v;
        });

        // data.filter.startDate = filtersArray.find((i)=>{ return i.k == "startDate"})?.v;
        // data.filter.endDate = filtersArray.find((i)=>{ return i.k == "endDate"})?.v;
        // data.filter.facility = filtersArray.find((i)=>{ return i.k == "facility"})?.v;
        // data.filter.currentResponsibleParty = filtersArray.find((i)=>{ return i.k == "currentResponsibleParty"})?.v;
        // data.filter.status = filtersArray.find((i)=>{ return i.k == "status"})?.v;
        // data.filter.provider = filtersArray.find((i)=>{ return i.k == "provider"})?.v;
        // data.filter.globalSearch = filtersArray.find((i)=>{ return i.k == "globalSearch"})?.v;
      }
    }

    function putFiltersToUrl() {
      const propsName = Object.getOwnPropertyNames(data.filter);

      let filters = [] as any;

      propsName.forEach((p) => {
        filters.push({ k: p, v: data.filter[p] });
      });

      filters = filters.filter((f) => {
        return f.v != undefined && f.v !== "" && f.v != null && f.v.length != 0;
      });

      if (filters.length > 0) {
        const currentRoute = route.fullPath;
        router.replace({
          path: currentRoute,
          query: { filters: JSON.stringify(filters) },
        });
      } else {
        router.replace({
          path: route.path,
        });
      }
    }

    async function selectArchiveOlderThanDays() {
      await updateArchiveOlderThanDays(
        store.getters.selectedRehab.id,
        data.archiveOlderThanDays
      );

      if (!data.archiveOlderThanDays) {
        data.archiveOlderThanDays = 120;
      }
      data.filter.endDate = new Date().toISOString();
      data.filter.startDate = new Date();
      data.filter.startDate.setDate(
        data.filter.startDate.getDate() - data.archiveOlderThanDays
      );
      data.filter.startDate = data.filter.startDate.toISOString();

      await search();
    }

    function getResponceParties(item) {
      let result = "";
      if (item.primaryPayer) {
        result = item.primaryPayer?.name;
        if (item.secondaryPayer) {
          result += ", " + item.secondaryPayer?.name;
          if (item.tertiaryPayer) {
            return (result += ", " + item.tertiaryPayer?.name);
          }
          return (result += ", Self");
        }
        return (result += ", Self");
      }
      return "Self";
    }

    function selectNotes(encounter) {
      data.modalEncounterId = encounter.id;
      data.modalPatientId = encounter.patientId;
      data.modal = true;
    }

    return {
      organizationId,
      clearSearch,
      data,
      selectItem,
      redirectToClaim,
      selectAllItemsIfManuallySelectedAll,
      checkDisplay,
      orderList,
      search,
      debounceSearch,
      getSortInfo,
      getResponceParties,
      pageChanged,
      pageSizeChanged,
      selectFilter,
      cleanFilter,
      getStatusHeader,
      sendAction,
      newEncounter,
      sendLineAction,
      selectAllItems,
      selectNotes,
      updateReason,
      updateReasonToEmpty,
      selectArchiveOlderThanDays,
      router,
      route,
    };
  },
});
